import * as href from './href';
import * as urls from './urls';
import * as sso from './sso';
import app, { getApp } from './app';
import environment from './environment';
import intercom from './intercom';
import errors from './errors';
import files from './files';

window.riskalive = {
  app,
  getApp,
  environment,
  urls,
  errors,
  sso,
  href,
  intercom,
  files,
};
