export const getApp = (url, port) => {
  let hostname = url.toLowerCase();

  if (port) {
    hostname += `:${port}`;
  }

  if (hostname.includes('sphinx') || hostname.includes('pha.riskalive.com') || hostname.includes('3803')) {
    return 'Sphinx';
  }

  if (hostname.includes('lorax') || hostname.includes('app.riskalive.com') || hostname.includes('3810')) {
    return 'Lorax';
  }

  if (hostname.includes('nanook') || hostname.includes('pha-analytics.riskalive.com') || hostname.includes('3802')) {
    return 'Nanook';
  }

  return 'Unknown';
};

export default getApp(window.location.hostname, window.location.port);
