export default (href) => {
  let currentUrl = new URL(window.location.href);
  if (currentUrl.searchParams.get('redirect')) {
    currentUrl = new URL(currentUrl.searchParams.get('redirect'));
  }

  const redirect = new URL(href);
  redirect.searchParams.delete('redirect');
  redirect.searchParams.append('redirect', currentUrl.href);

  window.location.href = redirect.href;
};
