import environment from './environment';

function getEnvironment(appName) {
  if (window.riskaliveOverrides && window.riskaliveOverrides[`${appName}Environment`]) {
    return window.riskaliveOverrides[`${appName}Environment`];
  }

  if (environment === 'Local') {
    return 'Development';
  }

  return environment;
}

function getHermesBaseUrl() {
  switch (getEnvironment('hermes')) {
    case 'Local':
      return 'https://localhost:38010';
    case 'Development':
      return 'https://hermes-dev.riskalive.com';
    case 'Integration':
      return 'https://hermes-dev.riskalive.com';
    case 'UAT':
      return 'https://hermes-uat.riskalive.com';
    case 'Staging':
      return 'https://hermes-staging.riskalive.com';
    default:
      return 'https://hermes.riskalive.com';
  }
}
export const hermes = getHermesBaseUrl();

function getLoraxBaseUrl() {
  switch (getEnvironment('lorax')) {
    case 'Local':
      if (window.location.hostname.toLowerCase() === '127.0.0.1') {
        return 'http://127.0.0.1:38100';
      }
      return 'http://lorax-local.riskalive.com';
    case 'Development':
      return 'https://lorax-dev.riskalive.com';
    case 'Integration':
      return 'https://lorax-dev.riskalive.com';
    case 'UAT':
      return 'https://lorax-uat.riskalive.com';
    case 'Staging':
      return 'https://lorax-staging.riskalive.com';
    default:
      return 'https://app.riskalive.com';
  }
}
export const lorax = getLoraxBaseUrl();

function getMedusaBaseUrl() {
  switch (getEnvironment('medusa')) {
    case 'Local':
    case 'Development':
    case 'Integration':
      return 'https://medusa-dev.riskalive.com';
    case 'UAT':
      return 'https://medusa-uat.riskalive.com';
    case 'Staging':
      return 'https://medusa-staging.riskalive.com';
    default:
      return 'https://medusa.riskalive.com';
  }
}
export const medusa = getMedusaBaseUrl();

function getMedusaApiBaseUrl() {
  switch (getEnvironment('medusaApi')) {
    case 'Local':
      return 'http://localhost:38060/';
    case 'Development':
      return 'https://medusa-api-dev.riskalive.com';
    case 'Integration':
      return 'https://medusa-api-dev.riskalive.com';
    case 'UAT':
      return 'https://medusa-api-uat.riskalive.com';
    case 'Staging':
      return 'https://medusa-api-staging.riskalive.com';
    default:
      return 'https://medusa-api.riskalive.com';
  }
}
export const medusaApi = getMedusaApiBaseUrl();

function getNanookBaseUrl() {
  switch (getEnvironment('nanook')) {
    case 'Local':
      return 'https://localhost:38020';
    case 'Development':
      return 'https://nanook-dev.riskalive.com';
    case 'Integration':
      return 'https://nanook-dev.riskalive.com';
    case 'UAT':
      return 'https://nanook-uat.riskalive.com';
    case 'Staging':
      return 'https://nanook-staging.riskalive.com';
    default:
      return 'https://pha-analytics.riskalive.com';
  }
}
export const nanook = getNanookBaseUrl();

function getOsirisBaseUrl() {
  switch (getEnvironment('osiris')) {
    case 'Local':
      return 'https://localhost:38070';
    case 'Development':
      return 'https://osiris-dev.riskalive.com';
    case 'Integration':
      return 'https://osiris-dev.riskalive.com';
    case 'UAT':
      return 'https://osiris-uat.riskalive.com';
    case 'Staging':
      return 'https://osiris-staging.riskalive.com';
    default:
      return 'https://osiris.riskalive.com';
  }
}
export const osiris = getOsirisBaseUrl();

function getPhoenixBaseUrl() {
  switch (getEnvironment('phoenix')) {
    case 'Local':
      return 'https://localhost:38040';
    case 'Development':
      return 'https://phoenix-dev.riskalive.com';
    case 'Integration':
      return 'https://phoenix-dev.riskalive.com';
    case 'UAT':
      return 'https://phoenix-uat.riskalive.com';
    case 'Staging':
      return 'https://phoenix-staging.riskalive.com';
    default:
      return 'https://phoenix.riskalive.com';
  }
}
export const phoenix = getPhoenixBaseUrl();

function getSphinxBaseUrl() {
  switch (getEnvironment('sphinx')) {
    case 'Local':
      return 'https://localhost:38030';
    case 'Development':
      return 'https://sphinx-dev.riskalive.com';
    case 'Integration':
      return 'https://sphinx-dev.riskalive.com';
    case 'UAT':
      return 'https://sphinx-uat.riskalive.com';
    case 'Staging':
      return 'https://sphinx-staging.riskalive.com';
    default:
      return 'https://pha.riskalive.com';
  }
}
export const sphinx = getSphinxBaseUrl();

function getTitanBaseUrl() {
  switch (getEnvironment('titan')) {
    case 'Local':
      return 'https://localhost:38050';
    case 'Development':
      return 'https://titan-dev.riskalive.com';
    case 'Integration':
      return 'https://titan-dev.riskalive.com';
    case 'UAT':
      return 'https://titan-uat.riskalive.com';
    case 'Staging':
      return 'https://titan-staging.riskalive.com';
    default:
      return 'https://titan.riskalive.com';
  }
}
export const titan = getTitanBaseUrl();
