import { KEY } from './token';

// Storage event listener handler that transfers localStorage from one tab to another.
const handler = (event) => {
  if (event.key !== KEY) {
    return;
  }

  console.debug('[SSO] Local Storage Listener: Called.');
  window.location.reload();
};

// listen for changes to localStorage
window.addEventListener('storage', handler, false);
