import { getToken } from './sso';
import { phoenix } from './urls';

export const download = async (url) => {
  const token = await getToken();
  const transferResponse = await fetch(`${phoenix}/api/account/token/transfer`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  const transferId = await transferResponse.text();

  const downloadUrl = new URL(url);
  downloadUrl.searchParams.append('auth-transfer-id', JSON.parse(transferId));
  window.open(downloadUrl.href);
};

export default {
  download,
};
