import { lorax } from '../urls';
import { set } from './token';
import redirect from '../helpers/redirect';

export default async () => {
  console.debug('[SSO] Logout: Called.');

  await set(null);
  redirect(`${lorax}/account/logout`);
};
