import app, { getApp } from '../app';
import isLoggedIn from './isLoggedIn';
import login from './login';

export { default as changeCompany } from './changeCompany';
export { default as isLoggedIn } from './isLoggedIn';
export { default as login } from './login';
export { default as logout } from './logout';
export { default as wrongCompany } from './wrongCompany';
export {
  get as getToken, set as setToken, decode as decodeToken, isExpired as tokenIsExpired,
} from './token';

/**
 * This method speeds up the initialization process on other apps(Sphinx,Nanook) and avoids double loading and uncessary back & forth redirects between apps.
 */
const init = async () => {
  console.debug('[SSO] Initialization: Called.');

  if (app === 'Lorax') {
    console.debug('[SSO] Initialization: Skipped.');
    return;
  }

  if (!await isLoggedIn()) {
    console.debug('[SSO] Initialization: Not logged in.');
    await login();
  } else if (getApp(document.referrer) === 'Lorax') {
    const url = new URL(document.location.href);
    if (url.searchParams.get('transfer') === 'true') {
      // Remove the transfer from the url.
      console.debug('[SSO] Initialization: Transferred.');
      url.searchParams.delete('transfer');
      window.history.replaceState(null, '', url.href);
    } else {
      console.debug('[SSO] Initialization: Not Transferred.');
      await login();
    }
  } else if (document.referrer && (new URL(document.referrer)).hostname !== document.location.hostname) {
    console.debug('[SSO] Initialization: Hostname Mismatch.');
    await login();
  }
};
init();
