import { lorax } from '../urls';
import app from '../app';
import redirect from '../helpers/redirect';

export default async () => {
  console.debug('[SSO] Login: Called.');

  if (app === 'Lorax') {
    redirect(`${lorax}/account/login`);
  } else {
    redirect(`${lorax}/account/authenticating`);
  }
};
